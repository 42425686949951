import React from "react"

import "./TermsOfService.scss"
import Container from "@material-ui/core/Container"

const TermsOfServiceContent = () => {
  return (
    <div className="terms-of-use">
      <Container>
        <div className="left-title">Terms of Use</div>
        <div className="content-text">Web Site Terms and Conditions of Use</div>
        <div className="content-title">Use License</div>
        <div className="content-description">
          1. Permission is granted to temporarily download one copy of the
          materials (information or software) on FT Technologies’s web site
          for personal, non-commercial transitory viewing only. This is the
          grant of a license, not a transfer of title, and under this license
          you may not:
          <br />
          <ul className="content-list">
            <li>modify or copy the materials;</li>
            <li>
              use the materials for any commercial purpose, or for any public
              display (commercial or non-commercial);
            </li>
            <li>
              attempt to decompile or reverse engineer any software contained on
              FTS Technologies’s web site
            </li>
            <li>
              remove any copyright or other proprietary notations from the
              materials;
            </li>
          </ul>
          2. This license shall automatically terminate if you violate any of
          these restrictions and may be terminated by FTS Technologies at any
          time. Upon terminating your viewing of these materials or upon the
          termination of this license, you must destroy any downloaded materials
          in your possession whether in electronic or printed format.
        </div>
        <div className="content-title">Disclaimer</div>
        <div className="content-description">
          The materials on FTS Technologies’s web site are provided “as is”.
          FTS Technologies makes no warranties, expressed or implied, and
          hereby disclaims and negates all other warranties, including without
          limitation, implied warranties or conditions of merchantability,
          fitness for a particular purpose, or non-infringement of intellectual
          property or other violation of rights. Further, FTS Technologies does
          not warrant or make any representations concerning the accuracy,
          likely results, or reliability of the use of the materials on its
          Internet web site or otherwise relating to such materials or on any
          sites linked to this site.
        </div>
        <div className="content-title">Limitations</div>
        <div className="content-description">
          In no event shall FTS Technologies or its suppliers be liable for any
          damages (including, without limitation, damages for loss of data or
          profit, or due to business interruption,) arising out of the use or
          inability to use the materials on FTS Technologies’s Internet site,
          even if FTS Technologies or a FTS Technologies authorized
          representative has been notified orally or in writing of the
          possibility of such damage. Because some jurisdictions do not allow
          limitations on implied warranties, or limitations of liability for
          consequential or incidental damages, these limitations may not apply
          to you.
        </div>
        <div className="content-title">Revisions and Errata</div>
        <div className="content-description">
          The materials appearing on FTS Technologies’s web site could include
          technical, typographical, or photographic errors. FTS Technologies
          does not warrant that any of the materials on its web site are
          accurate, complete, or current. FTS Technologies may make changes to
          the materials contained on its web site at any time without notice.
          FTS Technologies does not, however, make any commitment to update the
          materials.
        </div>
        <div className="content-title">Links</div>
        <div className="content-description">
          FTS Technologies has not reviewed all of the sites linked to its
          Internet web site and is not responsible for the contents of any such
          linked site. The inclusion of any link does not imply endorsement by
          FTS Technologies of the site. Use of any such linked web site is at
          the user’s own risk.
        </div>
        <div className="content-title">Site Terms of Use Modifications</div>
        <div className="content-description">
          FTS Technologies may revise these terms of use for its web site at
          any time without notice. By using this web site you are agreeing to be
          bound by the then current version of these Terms and Conditions of
          Use.
        </div>
        <div className="content-title">Governing Law</div>
        <div className="content-description">
          Any claim relating to FTS Technologies’s web site shall be governed
          by the laws of the State of Palestine without regard to its conflict
          of law provisions. General Terms and Conditions applicable to Use of a
          Web Site.
        </div>
      </Container>
    </div>
  )
}

export default TermsOfServiceContent
