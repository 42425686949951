import React from 'react';
import Layout from "../components/Layout"
import SEO from "../components/seo"
import TermsOfServiceContent from "../components/TermsOfServiceContent"

const TermsOfService = () => {
  return (
    <Layout currentPage="terms-of-service">
      <SEO title="Terms Of Service" />
      <TermsOfServiceContent />
    </Layout>
  );
};

export default TermsOfService;
